/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        $('.global_search .search-field').attr( 'placeholder' , 'Search Here....' );
        var menuRight = document.getElementById( 'mainnav' ),
	showRightPush = document.getElementById( 'mobile-menubtn' ),
        icon = showRightPush.querySelector( '.fa' ),
	body = document.body;
	overlay = document.getElementById( 'overlay' );

	showRightPush.onclick = function() {
          classie.toggle( this, 'active' );
	  classie.toggle( body, 'cbp-spmenu-push-toleft' );
	  classie.toggle( menuRight, 'cbp-spmenu-open' );
	  classie.toggle( overlay, 'menu-overlay' );
          classie.toggle( icon, 'fa-bars' );
          classie.toggle( icon, 'fa-times' );
	};
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);
    
    var slickCarousel = $('.carousel');
    slickCarousel.each(function() {
    if ($(this).is('.gold_slick')) {
      $(this).slick({
        autoplay: true,
        slidesToShow: 5,
        slidesToScroll: 1,
        responsive: [
          {
            breakpoint: 1200,
            settings: {
              slidesToShow: 4
            }
          },
          {
            breakpoint: 992,
            settings: {
              slidesToShow: 3
            }
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 2,
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
            }
          }
        ]
      });
    } 
    else if ($(this).is('.team_slick')){
      $(this).slick({
        responsive: [
          {
            breakpoint: 768,
            settings: {
              autoplay: false,
              slidesToShow: 1,
              slidesToScroll: 1,
            }
          }
        ]
      });
    }
    else {
      $(this).slick();
    }
    })
    
    $('.nav-primary .nav li > .sub-menu').parent().hover(function() {
      var submenu = $(this).children('.sub-menu');
      if ( $(submenu).is(':hidden') ) {
        $(submenu).slideDown(100);
      } else {
        $(submenu).slideUp(100);
      }
    });
    
    $('#infoChoice').on('change', function () {
      $("#general").css('display', (this.value == '0') ? 'block' : 'none');
      $("#competition").css('display', (this.value == '1') ? 'block' : 'none');
      $("#divisions").css('display', (this.value == '2') ? 'block' : 'none');
      $("#results").css('display', (this.value == '3') ? 'block' : 'none');
      $("#flighttimes").css('display', (this.value == '4') ? 'block' : 'none');
      $("#schedule").css('display', (this.value == '5') ? 'block' : 'none');
      $("#course").css('display', (this.value == '6') ? 'block' : 'none');
      $("#fees").css('display', (this.value == '7') ? 'block' : 'none');
      $("#volunteer-opps").css('display', (this.value == '8') ? 'block' : 'none');
      $("#seminar").css('display', (this.value == '9') ? 'block' : 'none');
      $("#photos").css('display', (this.value == '10') ? 'block' : 'none');
      $("#sponsors").css('display', (this.value == '11') ? 'block' : 'none');
      $("#athlete").css('display', (this.value == '12') ? 'block' : 'none');
      $("#rulesc").css('display', (this.value == '13') ? 'block' : 'none');
    });
    
    $('iframe[src*=youtube]').wrap(function() {
      var width = $(this).attr('width');
      $(this).removeAttr('frameborder');
      return '<div style="max-width:'+width+'px"><div class="video-container"></div></div>';
    });
    
    $('.srizon-pagination span.disabled').wrap('<li></li>');

    //=============== accordion photos and result page============================
    $('#prAccordion ul > li').first().find('h3').addClass('active');
    $('#prAccordion ul > li').first().find('h3 b').text('-');
    $('#prAccordion ul > li').on('click', 'h3', function(){      
      $(this).toggleClass('active').next().stop(true, true).slideToggle().parent().siblings().find('div.thisCnttype').slideUp();
      $(this).parent().siblings().find('h3').removeClass('active');
      if($(this).hasClass('active')){
        $(this).children('b').text('-');
        $(this).parent().siblings().find('h3 b').text('+');
      }else{
        $(this).children('b').text('+');
      }
      
    });
    $(window).load(function(){
      var resulttab = window.location.hash;
      
      if(resulttab){
        if(window.location.hash !='#resultTab1'){
        //console.log('aaaaaaaaaaaaaa')
      
        $("html, body").animate({ scrollTop: $('#prAccordion').offset().top}, 500);
        $(resulttab).find('h3').trigger('click');
        }
      }  
    });

     //=============== accordion photos and result END============================


})(jQuery); // Fully reference jQuery after this point.
